import { gridColumnLookupSelector, gridFilteredRowsLookupSelector, gridRowIdsSelector, gridRowTreeSelector } from '@mui/x-data-grid-pro';
import { getAggregationRules } from './gridAggregationUtils';
import { gridAggregationModelSelector } from './gridAggregationSelectors';

const getAggregationCellValue = ({
  apiRef,
  groupId,
  field,
  aggregationFunction,
  aggregationRowsScope
}) => {
  const rowTree = gridRowTreeSelector(apiRef);
  const filteredRowsLookup = gridFilteredRowsLookupSelector(apiRef);
  let rowIds; // TODO: Add custom root id

  if (groupId === '') {
    rowIds = gridRowIdsSelector(apiRef).filter(rowId => !rowTree[rowId].isAutoGenerated);
  } else {
    rowIds = apiRef.current.getRowGroupChildren({
      groupId
    });
  }

  const values = [];
  rowIds.forEach(rowId => {
    var _rowNode$children;

    if (aggregationRowsScope === 'filtered' && filteredRowsLookup[rowId] === false) {
      return;
    } // If the row is a group, we want to aggregate based on its children
    // For instance in the following tree, we want the aggregated values of A to be based on A.A, A.B.A and A.B.B but not A.B
    // A
    //   A.A
    //   A.B
    //     A.B.A
    //     A.B.B


    const rowNode = apiRef.current.getRowNode(rowId);

    if ((_rowNode$children = rowNode.children) != null && _rowNode$children.length) {
      return;
    }

    values.push(apiRef.current.getCellValue(rowId, field));
  });
  return aggregationFunction.apply({
    values
  });
};

const getGroupAggregatedValue = ({
  groupId,
  apiRef,
  aggregationRowsScope,
  aggregatedFields,
  aggregationRules,
  position
}) => {
  const groupAggregationLookup = {};

  for (let j = 0; j < aggregatedFields.length; j += 1) {
    const aggregatedField = aggregatedFields[j];
    const columnAggregationRules = aggregationRules[aggregatedField];
    groupAggregationLookup[aggregatedField] = {
      position,
      value: getAggregationCellValue({
        apiRef,
        groupId,
        field: aggregatedField,
        aggregationFunction: columnAggregationRules.aggregationFunction,
        aggregationRowsScope
      })
    };
  }

  return groupAggregationLookup;
};

export const createAggregationLookup = ({
  apiRef,
  aggregationFunctions,
  aggregationRowsScope,
  getAggregationPosition
}) => {
  const aggregationRules = getAggregationRules({
    columnsLookup: gridColumnLookupSelector(apiRef),
    aggregationModel: gridAggregationModelSelector(apiRef),
    aggregationFunctions
  });
  const aggregatedFields = Object.keys(aggregationRules);

  if (aggregatedFields.length === 0) {
    return {};
  }

  const aggregationLookup = {};
  const rowIds = gridRowIdsSelector(apiRef);
  const rowTree = gridRowTreeSelector(apiRef);

  for (let i = 0; i < rowIds.length; i += 1) {
    var _node$children;

    const rowId = rowIds[i];
    const node = rowTree[rowId];
    const hasChildren = (_node$children = node.children) == null ? void 0 : _node$children.some(childId => {
      var _rowTree$childId$posi;

      return ((_rowTree$childId$posi = rowTree[childId].position) != null ? _rowTree$childId$posi : 'body') === 'body';
    });

    if (hasChildren) {
      const position = getAggregationPosition(node);

      if (position != null) {
        aggregationLookup[rowId] = getGroupAggregatedValue({
          groupId: rowId,
          apiRef,
          aggregatedFields,
          aggregationRowsScope,
          aggregationRules,
          position
        });
      }
    }
  } // TODO: Add custom root id


  const position = getAggregationPosition(null);

  if (position != null) {
    aggregationLookup[''] = getGroupAggregatedValue({
      groupId: '',
      apiRef,
      aggregatedFields,
      aggregationRowsScope,
      aggregationRules,
      position
    });
  }

  return aggregationLookup;
};